<div class="col-12 text-right p-0">
  <form (submit)="changePassword()" [formGroup]="changePasswordForm">
    <div class="alert alert-info">
      <i class="icon-circle-info"></i>
      <p>به منظور افزایش امنیت، لطفا رمز خود را تغییر دهید.</p>
    </div>
    <div class="form-group mb-4">
      <label for="InputPassword1">رمز عبور جدید</label>
      <div class="input-container align-items-start flex-nowrap flex-row-reverse">
          <span class="hint-password">
            <span
              (click)="passwordToggle('password')"
              class="p-0"
            >
              <i
                *ngIf="passwordShow === 'password'"
                class="icon-eye-slash"
              ></i>
              <i
                *ngIf="passwordShow === 'text'"
                class="icon-view"
              ></i>
            </span>
          </span>
        <div class="w-100">
          <input [type]="passwordShow"
                 appFormError
                 autocomplete="new-password"
                 formControlName="newPassword"
                 id="InputPassword1"
                 name="newPassword"
                 placeholder="رمز جدید خود را وارد کنید."
                 required
                 tabindex="2"
          />
        </div>
      </div>
    </div>
    <div class="mb-4">
      <label for="InputPassword2">تکرار رمز عبور جدید</label>
      <div class="input-container align-items-start flex-nowrap flex-row-reverse">
         <span class="hint-password">
            <span
              (click)="passwordToggle('confirm')"
              class="p-0"
            >
              <i
                *ngIf="confirmPasswordShow === 'password'"
                class="icon-eye-slash"
              ></i>
              <i
                *ngIf="confirmPasswordShow === 'text'"
                class="icon-view"
              ></i>
            </span>
          </span>
        <div class="w-100">
          <input
            [type]="confirmPasswordShow"
            appFormError
            autocomplete="new-password"
            formControlName="confirmPassword"
            id="InputPassword2"
            name="confirmPassword"
            placeholder="رمز جدید خود را تکرار کنید."
            required
            tabindex="3"
          />
          <app-error
            *ngIf="
          changePasswordForm.hasError('notSame') &&
          !changePasswordForm.controls.confirmPassword.hasError('required') &&
          !changePasswordForm.controls.confirmPassword.pristine
        "
            [text]="'پسورد ها برابر نیستند.'"
          ></app-error>
        </div>
      </div>

    </div>
    <div class="text-center">
      <app-button [disabled]="!changePasswordForm.valid"
                  setClass="btn success-btn"
                  (click)="changePassword()">
        تغییر رمز عبور
      </app-button>
    </div>
  </form>
</div>
