import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RequestTrackerService {

  private requests: Map<string, number> = new Map();
  private requestTimes: Map<string, number[]> = new Map();

  constructor() {
  }

  logRequest(url: string): void {
    const now = Date.now();

    if (!this.requestTimes.has(url)) {
      this.requestTimes.set(url, []);
    }

    const times = this.requestTimes.get(url)!;
    times.push(now);

    // Clean up timestamps older than 1 second
    const oneSecondAgo = now - 1000;
    while (times.length && times[0] < oneSecondAgo) {
      times.shift();
    }

    // Update request count
    this.requests.set(url, times.length);
  }

  getRequestCount(url: string): number {
    return this.requests.get(url) || 0;
  }

  getAllRequests(): Map<string, number> {
    return this.requests;
  }
}
