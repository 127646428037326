import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {CookieService} from "ngx-cookie-service";

import {ConfigService} from "@shared/services/config.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router,
              private configService: ConfigService,
              private cookieService: CookieService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    if (this.cookieService.get(this.configService.authInfoKeyName)) {
      this.router.navigate(['']).then();
      return false;
    } else {

      return true;
    }
  }

}
