import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {SharedService} from "../../services/shared.service";
import {ValidatorsService} from "../../services/validators.service";

@Component({
  selector: 'app-one-input-modal',
  templateUrl: './one-input-modal.component.html',
  styleUrls: ['./one-input-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OneInputModalComponent implements OnInit {

  @Input() svg!: string;
  @Input() modalHeader!: string;
  @Input() modalText!: string;
  @Input() modalConfirmButton!: string;
  @Input() modalCancelButton!: string;
  @Input() initialValue!: string;
  @Input() inputConf!: { placeholder?: string, maxLength?: number, minLength?: null, type?: string };

  modalForm: FormGroup;

  constructor(public sharedService: SharedService,
              private formBuilder: FormBuilder,
              public ngbActiveModal: NgbActiveModal,
              private validatorsService: ValidatorsService) {
    this.modalForm = this.formBuilder.group({
      text: new FormControl('', [this.validatorsService.required()])
    });
  }

  ngOnInit(): void {
    if (this.initialValue !== undefined) {
      this.modalForm.controls.text.patchValue(this.initialValue);
    }
  }

  onSubmit() {
    this.ngbActiveModal.close(this.modalForm.controls.text.value);
  }

}
