export enum DeliveryStatusEnum {
  Delivered = 1,
  Undelivered = 2,
  ReachedTheOperator = 3,
  NotReachedTheOperator = 4,
  ReachedTheProvider = 5,
  Failed = 6,
  BlackList = 7,
  Unknown = 8
}
