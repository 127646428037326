import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-c404',
  templateUrl: './c404.component.html',
  styleUrls: ['./c404.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class C404Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
