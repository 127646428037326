import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {Router} from "@angular/router";

@Component({
  selector: 'app-active-line',
  templateUrl: './active-line.component.html',
  styleUrls: ['./active-line.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActiveLineComponent implements OnInit {
  @Input() modalHeader!: string;
  @Input() lineNumber!: string;
  constructor(public activeModal: NgbActiveModal, private router: Router ) { }

  ngOnInit(): void {
  }

  submit() {
    this.activeModal.dismiss();
    this.router.navigate(['/numbers/my-number'])
  }
}
