<div class="text-builder-wrapper">
  <div
    [ngClass]="{'has-template': textBuilderType !== textBuilderTypeEnum.Textarea, 'box-container': hasTransparentBackground}"
    class="text-builder-textarea box-container">
    <div *ngIf="cancellation" class="alert alert-info mb-3">
      <i class="icon-circle-info"></i>
      <p>متن ({{cancellation}}) به ‌طور اتوماتیک به انتهای پیامک شما اضافه می‌گردد.</p>
    </div>
    <form [formGroup]="form" class="content-box">
      <label>{{title}}</label>
      <textarea #message
                (errorStatusChanged)="errorStatusChanged($event)"
                (ngModelChange)="onMessageTextChange()"
                [autoGeneratedError]="false"
                [maxLength]="hasDoubleChar ? 932 : 2136"
                [ngClass]="{'input-error' : !!form.controls.text.hasError('maxLength')}"
                [style.height]="heightSize + 'px'"
                [style.min-height]="heightSize + 'px'"
                [needSpace]="true"
                appFormError
                dir="auto"
                formControlName="text"
                placeholder="متن پیام خود را اینجا بنویس..."
                rows="15">
             </textarea>
      <div class="count-sms-wrapper">
        <div class="cancellation-text">
          <span *ngIf="cancellation">{{cancellation}}</span>
        </div>
        <div class="count-sms">
          <span>{{localSmsLen}} / {{container}}</span> |
          <span>{{smsCount}} پیامک </span>
        </div>
      </div>
    </form>
    <div class="error-place">
      <div class="text-left">
        <app-error [haveSpace]="true" *ngIf="errorMsg" [text]="errorMsg"></app-error>
        <app-error [haveSpace]="true" *ngIf="form.controls.text.hasError('maxLength')"
                   [text]="'تعداد کارکتر وارد شده بیشتر از مقدار مجاز می باشد'"></app-error>
      </div>
    </div>
  </div>

  <div *ngIf="textBuilderType !== textBuilderTypeEnum.Textarea"
       [ngClass]="{'box-container': hasTransparentBackground}"
       class="text-builder-params box-container">

    <div *ngIf="textBuilderType === textBuilderTypeEnum.TemplateAndParametric ||
                textBuilderType === textBuilderTypeEnum.Template ||
                textBuilderType === textBuilderTypeEnum.Parametric"
         class="personalize-box">
      <ul class="tab-div">
        <li
          *ngIf="textBuilderType === textBuilderTypeEnum.Template || textBuilderType === textBuilderTypeEnum.TemplateAndParametric"
          class="title-tab">
          <a (click)="switchTab('templates')"
             [ngClass]="{'active' : activeTab === 'templates'}">پیش‌نویس‌ها</a>
        </li>
        <li
          *ngIf="textBuilderType === textBuilderTypeEnum.Parametric || textBuilderType === textBuilderTypeEnum.TemplateAndParametric"
          class="title-tab">
          <a (click)="switchTab('personalize')"
             [ngClass]="{'active' : activeTab === 'personalize'}">شخصی‌سازی</a>
        </li>
      </ul>

      <div *ngIf="activeTab === 'personalize'" class="personalize">
        <div *ngIf="isURL">
          <form [formGroup]="formURL" class="mt-3">
            <label>لطفا لینک خود را وارد کنید</label>
            <div class="d-flex align-items-center justify-content-between url-row mt-1">
              <div class="w-100">
                <input (input)="importURL($event)"
                       [dir]="'ltr'"
                       [value]=""
                       formControlName="webURL"
                       placeholder="لطفا لینک خود را وارد کنید"
                       type="text"
                />
              </div>
              <button (click)="generateURL()"
                      [disabled]="!formURL.valid"
                      class="secondary-btn btn mr-2">
                تبدیل
              </button>
            </div>
            <div class="hint">
              <i class="icon-circle-info"></i>
              <span>به‌صورت پیش‌فرض مقدار https در آدرس شما قرار می‌گیرد.</span>
            </div>
          </form>
        </div>
        <ng-container *ngIf="isURL">
          <div *ngFor="let parameter of parameters" class="parameter-item">
            <button (click)="addSegment(parameter, message)"
                    *ngIf="parameter.parameter === 'link'"
                    [disabled]="isShortLink"
                    class="url-add no-border green-text"> {{parameter.title ? parameter.title : parameter.parameter}}
            </button>
          </div>
        </ng-container>
        <div *ngFor="let parameter of parameters" class="parameter-item">
          <button (click)="addSegment(parameter, message)"
                  *ngIf="parameter.parameter !== 'link'"
                  class="green-text no-border"> {{parameter.title ? parameter.title : parameter.parameter}} </button>
        </div>
      </div>

      <div
        *ngIf="(textBuilderType === textBuilderTypeEnum.Template || textBuilderType === textBuilderTypeEnum.TemplateAndParametric) && activeTab === 'templates'"
        class="template">
        <div class="search-wrapper">
          <i class="icon-search search-wrapper__icon"></i>
          <input (input)="onInput($event)" class="search-wrapper__input" placeholder="جستجو..."
                 type="text">
        </div>
        <div class="template-container">
          <p *ngIf="!templates || templates.length === 0">پیش‌ نویسی یافت نشد.</p>
          <div class="template-list">
            <button (click)="selectTemplate(draft)"
                    *ngFor="let draft of templates;let i = index"
                    type="button">{{i + 1}}. {{draft.title}}</button>
          </div>
        </div>
      </div>

    </div>

    <div *ngIf="textBuilderType === textBuilderTypeEnum.FastSend" class="personalize-box">
      <ul class="tab-div">
        <li class="title-tab">
          <a class="active">پارامترهای شخصی‌سازی</a>
        </li>
      </ul>
      <div class="fast-send-details">
        <p>این پارامترها توسط شما و باتوجه به نیازتان ایجاد می‌شوند.</p>
        <p>اگر مایل به استفاده از اسامی متغییر در قالب نوشتاری هستید، کلمات موردنظر خود را در داخل # قرار
          دهید.</p>
        <p>مانند: #contacts#</p>
      </div>
      <div class="alert alert-info mt-2" role="alert">
        حداکثری در استفاده از پارامتر وجود ندارد.
      </div>
    </div>

  </div>
</div>
