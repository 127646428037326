import {EventEmitter, Injectable, Output} from '@angular/core';
import {RealTimeNotification} from "../models/real-time-notification";
import {Observable} from "rxjs";

import {PagedDataModel} from "@shared/models/paged-data.model";
import {ApiService} from "@shared/services/api.service";
import {ResponseModel} from "@shared/models/response.model";
import {NotificationInterface} from "../models/notification.interface";
import {SingleNotificationInterface} from "../models/single-notification.interface";


@Injectable({
  providedIn: 'root'
})
export class NotificationApiService {

  constructor(private apiService: ApiService) {
  }

  @Output() newUserNotificationCaught: EventEmitter<RealTimeNotification> = new EventEmitter<RealTimeNotification>();

  getSystemNotifications(queryParams: any, tableID: number): Observable<ResponseModel<PagedDataModel<NotificationInterface>>> {
    const url = `notification/system`;
    return this.apiService.get(url, queryParams, tableID);
  }

  getSystemNotificationSingle(notificationID: number): Observable<ResponseModel<SingleNotificationInterface>> {
    const url = `notification/system/${notificationID}`;
    return this.apiService.get(url);
  }

  getAdminNotifications(queryParams: any, tableID: number): Observable<ResponseModel<PagedDataModel<NotificationInterface>>> {
    const url = `notification/custom`;
    return this.apiService.get(url, queryParams, tableID);
  }

  getAdminNotificationSingle(notificationID: number): Observable<ResponseModel<SingleNotificationInterface>> {
    const url = `notification/custom/${notificationID}`;
    return this.apiService.get(url);
  }

  patchSystemNotificationsUnread(ids: number){
    const url = `notification/custom/read`;
    return this.apiService.patch(url , {ids});
  }

}
