import {Injectable} from '@angular/core';

import {ToastifyService} from "./toastify.service";
import {ServerErrors} from '../enums/server-error.enum';
import {AppNotifications} from '../enums/app-notifications.enum';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {PlanUpgradeModalComponent} from "@shared/components/plan-upgrade-modal/plan-upgrade-modal.component";
import {ConfigService} from "@shared/services/config.service";
import {StoreService} from "@shared/services/store.service";
import {PlanHandlerService} from "@shared/services/plan-handler.service";

@Injectable({
  providedIn: 'root'
})
export class HandleErrorService {
  private Unauthorized!: boolean;
  private Forbidden!: boolean;

  constructor(private toastifyService: ToastifyService,
              private planHandlerService: PlanHandlerService) {
  }

  handle(error: any): void {

    let errorCode;
    let errorType;
    let authError;

    if (error && error.status) {
      authError = error.status === 401 || error.status === 403;
      this.Unauthorized = error.status === 401;
      this.Forbidden = error.status === 403;
    } else {
      errorType = 'knownError';
      errorCode = error;
    }

    if (authError) {
      errorType = 'auth';
    } else {
      if (error.error) {
        if (error.error.ErrorCode) {
          errorType = 'knownError';
          errorCode = error.error.ErrorCode;
        } else if (error.error.title) {
          errorType = 'error';
          errorCode = null;
        } else {
          errorType = 'unknownError';
          errorCode = 0;
        }
      }
    }

    switch (errorType) {
      case 'knownError':
        if (errorCode >= 58 && errorCode < 72) {
          // @ts-ignore
          this.planHandlerService.planInterceptorHandler(AppNotifications[ServerErrors[errorCode] as keyof typeof ServerErrors])
        } else {
          this.toastifyService.error(
            ServerErrors[errorCode] &&
            AppNotifications[ServerErrors[errorCode] as keyof typeof ServerErrors] ?
              AppNotifications[ServerErrors[errorCode] as keyof typeof ServerErrors] : errorCode);
        }
        break;
      case 'error':
        this.toastifyService.error(error.error.title);
        break;
      case 'auth':
        this.authHandler();
        break;
      case 'unknownError':
        this.toastifyService.error(AppNotifications.UnknownError);
    }
  }

  authHandler(): void {
    if (!this.Unauthorized) {
      this.toastifyService.error(AppNotifications.LoginAgain);
    }
    if (this.Forbidden) {
      this.toastifyService.error(AppNotifications.LoginAgain);
    }
  }
}
