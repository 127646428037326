import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {TableLoadingModel} from "../models/table-loading.model";

@Injectable({
  providedIn: 'root'
})

export class LoadingService {
  private loadingState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loadingState$: Observable<boolean> = this.loadingState.asObservable();

  private tableLoadingState: BehaviorSubject<TableLoadingModel | undefined> = new BehaviorSubject<TableLoadingModel | undefined>(undefined);
  tableLoadingState$: Observable<TableLoadingModel | undefined> = this.tableLoadingState.asObservable();

  private buttonLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  buttonLoading$: Observable<boolean> = this.buttonLoading.asObservable();

  constructor() {
  }


  setLoadingState(event: boolean): void {
    this.loadingState.next(event);
  }

  setTableLoadingState(event: TableLoadingModel): void {
    this.tableLoadingState.next(event);
  }

  setButtonLoading(event: boolean): void {
    this.buttonLoading.next(event);
  }
}
