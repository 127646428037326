import {Injectable} from '@angular/core';
import {NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {environment} from "@env/environment";

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private $ServerURL = environment.serverUrl;
  private $baseURL = `${this.$ServerURL}api/app/`;
  private $counterPeriod = 120000;
  private $authInfoKeyName = 'access_token';
  private $prefix = 98;
  private $buttonDebounceTime = 200;
  private $devicesWidth = {
    mobile: {min: '0', max: '767'},
    tablet: {min: '768', max: '1024'},
    desktop: {min: '1024'}
  };
  private $modalOptions: NgbModalOptions = {
    centered: true,
    windowClass: 'm-modal',
    scrollable: true
  };

  get $serverURL(): string {
    return this.$ServerURL;
  }

  get buttonDebounceTime(): number {
    return this.$buttonDebounceTime;
  }

  get counterPeriod(): number {
    return this.$counterPeriod;
  }


  get serverURL(): string {
    return this.$ServerURL;
  }

  get baseURL(): string {
    return this.$baseURL;
  }

  get authInfoKeyName(): string {
    return this.$authInfoKeyName;
  }

  get prefix(): number {
    return this.$prefix;
  }

  get deviceWidth() {
    return this.$devicesWidth;
  }

  public modalOptions(size = 'sm', backdrop: boolean | 'static' = true, scrollable?: boolean) {
    if (size) {
      return {
        centered: true,
        scrollable,
        size,
        backdrop
      };
    } else {
      return this.$modalOptions;
    }
  }

  constructor() {
  }
}
