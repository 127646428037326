<div class="modal-header text-center">
  <img class="mb-3" src="assets/images/icons/modal/setting.svg">
  <h5>{{modalHeader}}</h5>
</div>

<div class="modal-body text-right">
  <p class="my-2 "> کاربر گرامی برای استفاده از قابلیت های سامانه باید حداقل یک خط فعال داشته باشید</p>
  <p class="my-2">شما هم اکنون یک خط با شماره {{lineNumber}} دارید که می‌توانید آن را فعال کنید</p>
</div>

<div class="modal-footer text-center">
  <button (click)="submit()" class="btn btn  success-btn">
    فعال سازی
  </button>
</div>
