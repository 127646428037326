import {Injectable} from '@angular/core';
import {Observable} from "rxjs";

import {ApiService} from "@shared/services/api.service";
import {ResponseModel} from "@shared/models/response.model";
import {PagedDataModel} from "@shared/models/paged-data.model";
import {TemplateInterface} from "../models/template.interface";
import {AddTemplateInterface} from "../models/add-template.interface";

@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  constructor(private apiService: ApiService) {
  }

  getTemplates(queryParams: any, tableID?: number): Observable<ResponseModel<PagedDataModel<TemplateInterface>>> {
    const url = `user-template`;
    return this.apiService.get(url, queryParams, tableID);
  }

  getSingleTemplate(templateId: number): Observable<ResponseModel<TemplateInterface>> {
    const url = `user-template/${templateId}`;
    return this.apiService.get(url);
  }

  addTemplate(payload: AddTemplateInterface): Observable<ResponseModel<number>> {
    const url = `user-template`;
    return this.apiService.post(url, payload);
  }

  editTemplate(id: number, payload: AddTemplateInterface): Observable<ResponseModel<boolean>> {
    const url = `user-template/${id}`;
    return this.apiService.put(url, payload);
  }

  deleteTemplate(templateId: number): Observable<ResponseModel<any>> {
    const url = `user-template/${templateId}`;
    return this.apiService.delete(url);
  }
}
