import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {tap} from "rxjs/operators";
import {RequestTrackerService} from "@shared/services/request-tracker.service";
import * as Sentry from "@sentry/angular-ivy";

@Injectable({
  providedIn: 'root'
})
export class LoggingInterceptorService implements HttpInterceptor {

  constructor(
    private requestTrackerService: RequestTrackerService,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = req.urlWithParams;
    this.requestTrackerService.logRequest(url);
    if (this.requestTrackerService.getRequestCount(url) > 10) {
      Sentry.captureMessage(url, "error")
    }

    return next.handle(req).pipe(
      tap(event => {
      })
    );
  }
}
