import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Loading} from "../../models/loading";

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingComponent implements OnInit {
  @Input() loadingOptions: Loading = {
    size: 'x-small',
    position: false,
    transparent: true,
    color: '#ffff',
    borderColor: 'transparent'
  }

  constructor() {
  }

  ngOnInit(): void {
  }

}
