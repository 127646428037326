import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TextBuilderService {
    parameters: { parameter: string; title: string; }[] =
        [
            {parameter: 'gender', title: '_جنسیت_',},
            {parameter: 'firstName', title: '_نام_مخاطب_',},
            {parameter: 'lastName', title: '_نام_خانوادگی_مخاطب_'},
            {parameter: 'mobile', title: '_موبایل_مخاطب_'},
            {parameter: 'link', title: '_لینک_کوتاه_'},
        ];

    constructor() {
    }

    convertToPersian(value: string) {
        let v: string = value;
        this.parameters.forEach(i => {
            v = v.split(`#${i.parameter}#`).join(`#${i.title}#`);
            // v = v.replace(`#${i.parameter}#`, `#${i.title}#`);
        });
        return v;
    }

    convertToEnglish(value: string) {
      let v: string = value;
        this.parameters.forEach(i => {
          v = v.split(`#${i.title}#`).join(`#${i.parameter}#`);
          // v = v.replace(`#${i.title}#`, `#${i.parameter}#`);
        });
        return v;
    }
}
