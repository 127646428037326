import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {CriticsSuggestionsService} from "./services/critics-suggestions.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ValidatorsService} from "../../services/validators.service";
import {ToastifyService} from "../../services/toastify.service";

@Component({
  selector: 'app-critics-suggestions',
  templateUrl: './critics-suggestions.component.html',
  styleUrls: ['./critics-suggestions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CriticsSuggestionsComponent implements OnInit {
  @Input() modalHeader!: string;
  criticsAndSuggestions: FormGroup;

  constructor(private formBuilder: FormBuilder,
              private criticsSuggestionsService: CriticsSuggestionsService,
              private activeModal: NgbActiveModal,
              private toastifyService: ToastifyService,
              private validatorsService: ValidatorsService) {
    this.criticsAndSuggestions = this.formBuilder.group({
      content: new FormControl('', [this.validatorsService.required()])
    });
  }

  ngOnInit(): void {
  }

  submit() {
    const feedback = {
      content: this.criticsAndSuggestions.controls.content.value
    }
    this.activeModal.dismiss();
    this.criticsSuggestionsService.addFeedback(feedback)
      .subscribe(res => {
        if (res.result) {
          this.toastifyService.success('نظر شما با موفقیت ارسال شد.');
        }
      })
  }

  cancel(){
    this.activeModal.close();
  }
}
