import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {StoreService} from "@shared/services/store.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {BootstrapperModel} from "@shared/models/bootstrapper.model";

@UntilDestroy()
@Component({
  selector: 'app-update-modal',
  templateUrl: './update-modal.component.html',
  styleUrls: ['./update-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpdateModalComponent implements OnInit {

  modalText!: string;

  constructor(private storeService: StoreService,
              public ngbActiveModal: NgbActiveModal) {
    const userBaseData: BootstrapperModel | null = this.storeService.getBaseData();
    this.modalText = userBaseData
      ? `${userBaseData.userData.firstName} ${userBaseData.userData.lastName} عزیز، نسخه جدید سامانه پیامکی SMS.ir در دسترس است. لطفا سیستم خود را به روزرسانی نمایید. `
      : `کاربر عزیز، نسخه جدید سامانه پیامکی SMS.ir در دسترس است. لطفا سیستم خود را به روزرسانی نمایید. `
    this.storeService.userInfo$
      .pipe(untilDestroyed(this))
      .subscribe(res => {})
  }

  ngOnInit(): void {
  }

  onSubmit() {
    this.ngbActiveModal.close(true);
  }

}
