import {Injectable} from '@angular/core';
import {SwUpdate, UpdateAvailableEvent} from "@angular/service-worker";
import {interval} from "rxjs";
import {ConfigService} from "@shared/services/config.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {untilDestroyed} from "@ngneat/until-destroy";
import {ActivatedRoute, Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class WorkerService {

  hasUpdate: boolean = false;

  constructor(private updates: SwUpdate,
              private configService: ConfigService,
              private ngbModal: NgbModal,
              private router: Router,

  ) {
    if (updates.isEnabled) {
      interval(60000)
        .pipe(untilDestroyed(this))
        .subscribe(() => updates.checkForUpdate()
        );
    }
  }

  public checkForUpdates() {
    if (this.updates.isEnabled) {
      this.updates.available.subscribe(event => {
        this.hasUpdate = true;
        if(this.router.url === "/dashboard"){
          this.reloadPage();
        }
        // const modalRef = this.ngbModal.open(UpdateModalComponent, this.configService.modalOptions('sm', 'static'));
        // modalRef.result.then(res => {
        //   if (res) {
        //     this.promptUser(event);
        //   }
        // })
      });
    }
  }

  // If there is an update, promt the user
  private promptUser(e?: UpdateAvailableEvent): void {
    this.updates.activateUpdate().then(() => window.location.reload());
  }

  public reloadPage() {
    this.promptUser()
  }
}
