<div class="modal-header  mb-3">
  <h1>{{modalHeader}}</h1>
</div>

<form (submit)="submit()" [formGroup]="criticsAndSuggestions" class="form">
  <div class="row mb-3">
    <div class="col-12">
      <textarea [maxLength]="2000"
                appFormError
                formControlName="content"
                placeholder="لطفا انتقاد و پیشنهاد خود را وارد کنید."
                rows="8"></textarea>
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-center">
      <button [disabled]="!criticsAndSuggestions.valid"
              class="btn success-btn"
              type="submit"
      >
        ارسال
      </button>
      <button (click)="cancel()"
              class="btn dark-btn mr-3"
              type="reset"
      >
        بازگشت
      </button>
    </div>
  </div>
</form>
