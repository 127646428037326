export enum SendTowerState {
  Pending = 1,
  Rejected = 2,
  Accepted = 3,
  ManualPaymentReview = 4,
  Paid = 5,
  Ready = 6,
  Finished = 7,
  Expired = 8
}
