import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ConfigService} from './config.service';
import {TokenService} from './token.service';
import {LoadingService} from './loading.service';
import {ToastifyService} from '@shared/services/toastify.service';
import {DemoHandler} from '@shared/services/demo-handler.service';
import {ActivatedRoute} from '@angular/router';


@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  httpOptions: any = {
    headers: new HttpHeaders({'Content-Type': 'application/json'}),
    observe: 'response'
  };
  private readonly serverURL: string;

  constructor(private httpClient: HttpClient,
              private configService: ConfigService,
              private loadingService: LoadingService) {
    this.serverURL = `${this.configService.$serverURL}api/app/`;
  }

  cleanUpPayload(payload: any) {
    if (payload && typeof payload === 'object') {
      Object.keys(payload).forEach(el => {
        if (typeof payload[el] === 'string') {
          payload[el] = payload[el].trim();
        }
      });
    }
  }

//todo : change get method queryParam to httpParam angular (QueryParamsModel)

  getArrayQueryParam<T>(url: string, options: any, needAuth: boolean, tableID?: number) {
    return this.httpClient.get<T>(this.serverURL + url, needAuth ? options : this.httpOptions)
      .pipe(
        map((res: any) => {
          if (tableID) {
            this.loadingService.setTableLoadingState({[tableID]: false});
          }
          return res.body;
        })
      );

  }

  get<T>(url: string, queryParams?: any, tableID?: number): Observable<T> {
    const queryParamsLocal = {...queryParams};
    if (tableID) {
      this.loadingService.setTableLoadingState({[tableID]: true});
    }
    if (queryParams) {
      for (const key in queryParamsLocal) {
        if (queryParamsLocal.hasOwnProperty(key)) {
          if (queryParamsLocal[key] === undefined || queryParamsLocal[key] === null) {
            delete queryParamsLocal[key];
          }
        }
      }
    }
    let options: any = {
      ...this.httpOptions,
      params: queryParamsLocal
    };

    return this.httpClient.get<T>(this.serverURL + url, options)
      .pipe(
        map((res: any) => {
          if (tableID) {
            this.loadingService.setTableLoadingState({[tableID]: false});
          }
          return res.body;
        })
      );
  }

  post<T>(url: string, payload: T): any {
    this.cleanUpPayload(payload);
    return this.httpClient.post<T>((this.serverURL) + url, payload,  this.httpOptions)
      .pipe(
        map((res: any) => {
          return res.body;
        })
      );
  }

  put<T>(url: string, payload: T): any {
    this.cleanUpPayload(payload);
    return this.httpClient.put<T>((this.serverURL) + url, payload,  this.httpOptions)
      .pipe(
        map((res: any) => {
          return res.body;
        })
      );
  }

  patch<T>(url: string, payload: T): Observable<any> {
    this.cleanUpPayload(payload);
    return this.httpClient.patch<T>((this.serverURL) + url, payload, this.httpOptions)
      .pipe(
        map((res: any) => {
          return res.body;
        })
      );
  }

  delete<T>(url: string, payload?: T): any {
    this.cleanUpPayload(payload);
    let options: any = {
      ...this.httpOptions
    }
    if (payload) {
      options = {
        ...options,
        body: payload
      };
    }
    return this.httpClient.delete<T>((this.serverURL) + url, options)
      .pipe(
        map((res: any) => {
          return res.body;
        })
      );
  }

  postFile(url: string, file: any): any {
    return this.httpClient.post((this.serverURL) + url, file, {
      reportProgress: false,
      observe: 'response',
    })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }


  putFile(url: string, file: any): any {
    return this.httpClient.put((this.serverURL) + url, file, {
      reportProgress: false,
      observe: 'response',
    })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  patchFile(url: string, file: any): any {
    return this.httpClient.patch((this.serverURL) + url, file, {
      reportProgress: false,
      observe: 'response',
    })
      .pipe(
        map((res: any) => {
          return res.body;
        })
      );
  }


}
