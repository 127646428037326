import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

import {SharedService} from "@shared/services/shared.service";
import {SimpleSinglePreviewInterface} from "@app/modules/send-message/models/simple-single-preview.interface";

@Component({
  selector: 'app-contact-preview',
  templateUrl: './contact-preview.component.html',
  styleUrls: ['./contact-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactPreviewComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal,
              public sharedService: SharedService) {
  }

  @Input() needConfirm!: boolean;
  @Input() data!: SimpleSinglePreviewInterface;

  ngOnInit(): void {
  }

  submit() {
    if (this.data.distinctCount !== 0) {
      this.activeModal.close(true);
    }
  }

}
