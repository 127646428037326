import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {SharedService} from "@shared/services/shared.service";
import {SimpleSinglePreviewInterface} from "@app/modules/send-message/models/simple-single-preview.interface";

@Component({
  selector: 'app-operator-preview',
  templateUrl: './operator-preview.component.html',
  styleUrls: ['./operator-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OperatorPreviewComponent implements OnInit {

  objectKeys = Object.keys;

  constructor(public sharedService: SharedService,
              public activeModal: NgbActiveModal) {
  }
  @Input() needConfirm!: boolean;
  @Input() data!: SimpleSinglePreviewInterface;

  ngOnInit(): void {
  }

}
