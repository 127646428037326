export enum CampaignItemEvaluationTypeEnum {
  DidNotHaveGoodFeedBack = 1,
  NoStatistics = 2,
  FairlyGood = 3,
  TheBestTest = 4,
  TheWorstTest = 5,
  MoreThan50 = 6,
  MoreThan75 = 7,
  LessThan50 = 8,
  Successful = 9,
  Failed = 10,
}
