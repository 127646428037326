<div *ngIf="show" class="initial-loading d-flex justify-content-center align-items-center">

    <div class="box-loading d-flex flex-column justify-content-center align-items-center ">

        <div class="box-loading-image-section">

            <div class="first-image">
                <img [src]="image1" alt="logo" height="96" width="96">
            </div>

            <div class="second-image">
                <img [src]="image2" alt="logo" height="96" width="96">
            </div>

        </div>

        <div class="box-loading-text mt-4 text-center">
            <h1><b><strong>از صبر و شکیبایی شما سپاسگزاریم</strong></b></h1>
        </div>

    </div>

</div>
