export enum SendMessageEnum {
  FastSend = 1,
  FastSendTest = 2,
  BulkSimpleSend = 3,
  ParametricSmartSend = 4,
  MultipleTextSmartSend = 5,
  SimpleCampaign = 6,
  ScheduledCampaign = 7,
  EventCampaign = 8,
  ApiSend = 9,
  SmsSecretary = 10,
  SmsTransmission = 11,
  ABTestCampaign = 12,
  MobileBank = 13,
  CodeReader = 14,
  TowerSend = 15,
}
