import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {SharedService} from "@shared/services/shared.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-template-confirmation-modal',
  templateUrl: './template-confirmation-modal.component.html',
  styleUrls: ['./template-confirmation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class TemplateConfirmationModalComponent implements OnInit {

  title = 'تایید قالب';
  description = 'متن شما حاوی کاراکتر ویژه # می‌باشد. کاربرد این کاراکتر زمانی است که به مخاطبان دفترچه‌تلفن خود ارسال نمایید. \n در غیراینصورت، به همان ترتیب در متن نهایی، درج خواهد شد.'
  @Input() modalConfirmButton!: string;
  @Input() modalCancelButton!: string;

  constructor(public sharedService: SharedService,
              public ngbActiveModal: NgbActiveModal) {
  }

  ngOnInit(): void {
  }

  onSubmit() {
    this.ngbActiveModal.close(true);
  }
}
