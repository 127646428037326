import {Injectable} from '@angular/core';
import {Observable} from "rxjs";

import {ResponseModel} from "@shared/models/response.model";
import {ApiService} from "@shared/services/api.service";
import {GuestInfoModel} from "../models/guest-info.model";
import {GuestLegalData} from "../models/guest-legal-data";
import {GuestNationalData} from "../models/guest-national-data";
import {GuestAddress} from "../models/guest-address";

@Injectable({
  providedIn: 'root'
})
export class GuestApiService {

  constructor(private apiService: ApiService) {
  }

  getGuestInfo(): Observable<ResponseModel<GuestInfoModel>> {
    const url = 'Guest';
    return this.apiService.get(url);
  }

  getProvince() : Observable<ResponseModel<{name: string , value: number}[]>> {
    const url = 'setting/province';
    return this.apiService.get(url);
  }

  getCity(provinceId: number): Observable<ResponseModel<{name: string , value: number}[]>> {
    const url = `setting/province/${provinceId}/city`;
    return this.apiService.get(url);
  }


  getVerificationCode(mobile: number): Observable<ResponseModel<boolean>> {
    const url = 'guest/additional-mobile/verification-code';
    return this.apiService.post(url, mobile);
  }

  verifyCode(code: { verificationCode: number, mobile: number }): Observable<ResponseModel<any>> {
    const url = 'guest/additional-mobile/verification-code';
    return this.apiService.patch(url, code);
  }

  modifyNaturalData(payload: GuestNationalData): Observable<ResponseModel<any>> {
    const url = 'guest/natural';
    return this.apiService.put(url, payload);
  }

  modifyLegalData(payload: GuestLegalData): Observable<ResponseModel<any>> {
    const url = 'guest/legal';
    return this.apiService.put(url, payload);
  }

  modifyAddress(payload: GuestAddress): Observable<ResponseModel<any>> {
    const url = 'guest/address';
    return this.apiService.put(url, payload);
  }

  uploadLegal(file: File , alreadyHave: boolean): Promise<ResponseModel<any> | any> {
    const url = alreadyHave? 'guest/finish' : 'guest/gazette';
    const payload = new FormData();
    payload.append('file', file);
    return alreadyHave ? this.apiService.patch(url, null).toPromise() : this.apiService.postFile(url, payload).toPromise();
  }

  uploadNatural(file: File , alreadyHave: boolean): Promise<ResponseModel<any> | any> {
    const url = alreadyHave? 'guest/national-card/skip' : 'guest/national-card';
    const payload = new FormData();
    payload.append('file', file);
    return alreadyHave? this.apiService.patch(url, null).toPromise() :  this.apiService.postFile(url, payload).toPromise();
  }

  // skipUploadNatural(): Observable<ResponseModel<any> | any> {
  //   const url = 'guest/national-card/skip';
  //   return this.apiService.patch(url, null, true);
  // }
  //
  // confirmGuestInfoWithoutUploadNewGazetteCard(): Observable<ResponseModel<any>> {
  //   const url = 'guest/finish';
  //   return this.apiService.patch(url, null, true);
  // }
}
