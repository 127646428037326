<div class="delete-modal">
  <div class="modal-header text-center ">
    <img [src]="svg ? svg : sharedService.DeleteSVG" alt="delete-icon">
    <h5>{{modalHeader}}</h5>
  </div>
  <div class="modal-body text-center">
    <p class="text-center">{{modalText}}</p>
  </div>
  <div class="modal-footer text-center">
    <button (click)="onSubmit()" class="btn danger-btn">
      {{modalConfirmButton ? modalConfirmButton : 'حذف'}}
    </button>
    <button (click)="ngbActiveModal.dismiss()"
            class="btn dark-btn mr-3">{{modalCancelButton ? modalCancelButton : 'بازگشت' }}</button>
  </div>

</div>

