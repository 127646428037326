import { Injectable } from '@angular/core';
import {HelpModel} from "../models/help.model";
import {BehaviorSubject, Observable} from "rxjs";
import {HelpModeType} from "../models/help-mode.type";

@Injectable({
  providedIn: 'root'
})
export class HelpService {
  private $helpItems!: HelpModel[];

  private selectedHelp: BehaviorSubject<HelpModel | undefined> = new BehaviorSubject<HelpModel  | undefined>(undefined)
  selectedHelp$: Observable<HelpModel  | undefined> = this.selectedHelp.asObservable();


  selectHelp(data : HelpModel | undefined){
    this.selectedHelp.next(data)
  }

  get helpItems() {
    return this.$helpItems;
  }

  set helpItems(items) {
    this.$helpItems = items;
  }

  private $helpId!: number;

  get helpId() {
    return this.$helpId;
  }

  set helpId(id) {
    this.$helpId = id;
  }

  constructor() { }
}
