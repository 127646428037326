import {Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {BehaviorSubject, Observable} from 'rxjs';
import jwtDecode from 'jwt-decode';

import {StoreService} from './store.service';
import {ILoginResultView} from '../../auth/models/ilogin-result.view';
import {Router} from '@angular/router';
import {DecodeJWTModel} from "@shared/models/auth-info.model";
import {HttpHeaders} from "@angular/common/http";
import {map, switchMap} from "rxjs/operators";
import {ApiService} from "@shared/services/api.service";
import {ConfigService} from "@shared/services/config.service";
import {ResponseModel} from "@shared/models/response.model";
import {UserStateEnum} from "@shared/enums/user-state.enum";

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private _ACCESS_TOKEN = 'access_token';
  private _REFRESH_TOKEN = 'refresh_token';

  private isAuthenticated!: BehaviorSubject<boolean>;
  isAuthenticated$!: Observable<boolean>;

  constructor(private cookieService: CookieService,
              private router: Router,
              private storeService: StoreService,
              private apiService: ApiService,
              private configService: ConfigService,
  ) {
    this.isAuthenticated = new BehaviorSubject<boolean>(this.hasAccessToken() && !this.isTokenExpired());
    this.isAuthenticated$ = this.isAuthenticated.asObservable();
  }

  setIsAuthenticated(isAuthenticated: boolean): void {
    this.isAuthenticated.next(isAuthenticated);
  }

  setTokenItems(LoginResult: ILoginResultView): void {
    this.cookieService.set(this._ACCESS_TOKEN, LoginResult.accessToken, {path: '/'});
    this.cookieService.set(this._REFRESH_TOKEN, LoginResult.refreshToken, {path: '/'});
    const decodeToken: DecodeJWTModel = jwtDecode(LoginResult.accessToken);
    this.storeService.setToken(LoginResult.accessToken);
    this.storeService.setIsGuest(decodeToken.hasOwnProperty('GuestId'));
    this.storeService.setUserState(Number(decodeToken.State) ?? null)
    this.setIsAuthenticated(true);
  }

  setTokenItemsWithRefreshToken(LoginResult: string, refreshToken: string): boolean {
    const decodeToken: DecodeJWTModel = jwtDecode(LoginResult);
    let newState = Number(decodeToken.State);
    let isStateChanged: boolean = false;
    if (
      (this.storeService.getUserState() !== newState) &&
      this.storeService.getUserState()! < newState) {
      this.router.navigate(['complete-information']).then();
      isStateChanged = true
    }
    this.cookieService.deleteAll('/');
    this.cookieService.deleteAll('/', '.sms.ir');
    // this.cookieService.set(this._ACCESS_TOKEN, LoginResult, {path: '/'});
    this.cookieService.set(this._ACCESS_TOKEN, LoginResult, {path: '/'});
    this.cookieService.set(this._REFRESH_TOKEN, refreshToken, {path: '/'});
    this.storeService.setToken(LoginResult);
    this.storeService.setIsGuest(decodeToken.hasOwnProperty('GuestId'));
    this.storeService.setUserState(newState  ?? null)
    this.setIsAuthenticated(true);
    return isStateChanged
  }

  getAccessToken(): string {
    return this.cookieService.get(this._ACCESS_TOKEN);
  }

  getRefreshToken(): string {
    return this.cookieService.get(this._REFRESH_TOKEN);
  }

  removeAllToken(): void {
    this.cookieService.deleteAll('/');
    this.cookieService.deleteAll('/', '.sms.ir');
  }

  hasAccessToken(): boolean {
    const hasAccessToken = this.getAccessToken();
    return hasAccessToken !== '';
  }

  private isTokenExpired(): boolean {
    if (!this.hasAccessToken()) {
      throw new Error('access token does not found');
    }

    const expireTime = this.getAccessTokenExpireTime();
    const now = Math.floor(new Date().getTime() / 1000) + 10;
    return now > expireTime;
  }

  private getAccessTokenExpireTime(): number {
    const accessToken = this.getAccessToken();
    const decodeToken: DecodeJWTModel = jwtDecode(accessToken);
    return Number(decodeToken.exp);
  }

  logOut() {
    this.removeAllToken();
    this.storeService.setIsGuest(undefined);
    this.storeService.setToken(undefined);
    window.location.href = 'https://sms.ir/';
  }


  public getNewToken(refreshToken: string): Observable<ResponseModel<string>> {
    return this.apiService.put('auth/token', JSON.stringify(refreshToken))
      .pipe(
        map((res: ResponseModel<string>) => {
          this.setTokenItemsWithRefreshToken(res.result, refreshToken.trim());
          return res
        })
      )
  }
}
