export enum ActionNameEnum {
    VerifySend = 1,
    BulkSend = 2,
    PeerToPeerSend = 3,
    UrlSend = 4,
    RemoveScheduledMessages = 5,
    GetSentMessage = 6,
    GetPack = 7,
    GetLivePacks = 8,
    GetSendLiveReport = 9,
    GetSendArchiveLiveReport = 10,
    GetReceiveLatestMessages = 11,
    GetReceiveLiveReport = 12,
    GetReceiveArchiveReport = 13,
    GetUserLines = 14,
    GetUserCredit = 15
}
