export enum PlanItemTypeEnum {
  BulkSend = 1,
  MobileBankSend = 2,
  ParametricSend = 3,
  MultipleTextSend = 4,
  ScheduledCampaign = 5,
  ABTestCampaign = 6,
  EventCampaign = 7,
  ReceivedMessagesReport = 8,
  UserTemplate = 9,
  BlackListInquiryCount = 10,
  SaleCooperation = 11,
  PersonalBlackListKeyword = 12,
  FastSend = 13,
  ApiKey = 14,
  IPLimitationOnApiKeys = 15,
  SmsTransmissionApiParameterless = 16,
  SmsTransmissionApiWithParameter = 17,
  SmsTransmissionEmailParameterless = 18,
  SmsTransmissionEmailWithParameter = 19,
  SmsTransmissionSmsParameterless = 20,
  SmsTransmissionSmsWithParameter = 21,
  SmsSecretaryParameterless = 22,
  SmsSecretaryWithParameter = 23,
  CodeReader = 24,
  CodeReaderParameters = 25,
  Contacts = 26,
  ContactGroups = 27,
  ContactEvents = 28,
  ConcurrentOpenTicketsCount = 29,
  WebServices = 30
}
