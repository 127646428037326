import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {UtilityService} from "@shared/services/utility.service";
import {ValidatorsService} from "@shared/services/validators.service";
import {ToastifyService} from "@shared/services/toastify.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {AppNotifications} from "@shared/enums/app-notifications.enum";
import {PasswordModalService} from "@shared/components/password-modal/services/password-modal.service";
import {StoreService} from "@shared/services/store.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@UntilDestroy()
@Component({
  selector: 'app-password-modal',
  templateUrl: './password-modal.component.html',
  styleUrls: ['./password-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordModalComponent implements OnInit {
  changePasswordForm!: FormGroup;
  passwordShow = 'password';
  confirmPasswordShow = 'password';
  newPassword = '';
  isGuest!: boolean;

  constructor(private formBuilder: FormBuilder,
              private utilityService: UtilityService,
              private validatorsService: ValidatorsService,
              private passwordModalService: PasswordModalService,
              private toastifyService: ToastifyService,
              private activeModal: NgbActiveModal,
              private storeService: StoreService,
              private changeDetectorRef: ChangeDetectorRef) {

    this.createChangePasswordForm();
  }

  ngOnInit(): void {
    this.storeService.isGuest$
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.isGuest = res;
      })
  }

  createChangePasswordForm() {
    this.changePasswordForm = this.formBuilder.group({
      newPassword: new FormControl('', [this.validatorsService.required(), this.validatorsService.password()]),
      confirmPassword: new FormControl('', [this.validatorsService.required()])
    }, {validator: this.validatorsService.checkMatchPassword('newPassword', 'confirmPassword')});
  }

  changePassword() {
    if (this.changePasswordForm.valid) {
      this.newPassword = this.changePasswordForm.controls.newPassword.value
      this.passwordModalService.setPassword(JSON.stringify(this.newPassword), this.isGuest)
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.newPassword = '';
          this.toastifyService.success(AppNotifications.PasswordChangeSuccess);
          this.createChangePasswordForm();
          this.changeDetectorRef.detectChanges();
          this.activeModal.close(true)
        });
    }
  }

  passwordToggle(event: any) {
    if (event === 'password') {
      if (this.passwordShow === 'password') {
        this.passwordShow = 'text';
      } else if (this.passwordShow === 'text') {
        this.passwordShow = 'password';
      }
    }
    if (event === 'confirm') {
      if (this.confirmPasswordShow === 'password') {
        this.confirmPasswordShow = 'text';
      } else if (this.confirmPasswordShow === 'text') {
        this.confirmPasswordShow = 'password';
      }
    }
  }
}
