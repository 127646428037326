import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {SharedService} from "../../services/shared.service";
import {ShowDataModalInterface} from "./models/show-data-modal.interface";

@Component({
  selector: 'app-show-data-with-confirm-modal',
  templateUrl: './show-data-with-confirm-modal.component.html',
  styleUrls: ['./show-data-with-confirm-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShowDataWithConfirmModalComponent implements OnInit {

  @Input() modalText!: string;
  @Input() modalHeader!: string;
  @Input() data!: ShowDataModalInterface;
  @Input() svg!: string;
  @Input() hasSecondButton!: boolean;
  @Input() textSecondButton!: string;
  @Input() classNameSecondButton!: string;

  objectKeys = Object.keys;

  constructor(public sharedService: SharedService,
              public activeModal: NgbActiveModal,
              public ngbActiveModal: NgbActiveModal,
              ) {
  }

  ngOnInit(): void {
  }

  onSubmit() {
    this.ngbActiveModal.close(true);
  }

}
