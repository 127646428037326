import {Injectable} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Router} from "@angular/router";
import {Location} from "@angular/common";
import {Observable, of} from "rxjs";
import {catchError, map, tap} from "rxjs/operators";
import {UntilDestroy} from "@ngneat/until-destroy";

import {PlanUpgradeModalComponent} from "@shared/components/plan-upgrade-modal/plan-upgrade-modal.component";
import {ConfigService} from "@shared/services/config.service";
import {StoreService} from "@shared/services/store.service";
import {IUserPlanData} from "@shared/models/i-user-plan-data";
import {ToastifyService} from "@shared/services/toastify.service";
import {PlansService} from "@app/modules/plans/services/plans.service";
import {BootstrapperModel} from "@shared/models/bootstrapper.model";
import {PlanItemTypeEnum} from "@app/modules/plans/enums/plan-item-type.enum";


@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class PlanHandlerService {
  userPlanData!: IUserPlanData | undefined;

  constructor(private ngbModal: NgbModal,
              private storeService: StoreService,
              private location: Location,
              private router: Router,
              private toastifyService: ToastifyService,
              private plansService: PlansService,
              private configService: ConfigService) {
  }

  hasPlan(): Observable<boolean> {
    return this.storeService.baseData$.pipe(
      map((bootstrap: null | BootstrapperModel) => {
        if(!bootstrap) return false;

        if(!bootstrap.userPlanData) return false;

        return bootstrap.userPlanData.expireAt >= Math.floor(Date.now() / 1000);
      }),
    )
  }

  checkLimitation(planItem: PlanItemTypeEnum): Observable<boolean> {
    return this.plansService.getLimitation(planItem).pipe(
      map(resp => resp.result),
      catchError(() => {
        return of(false)
      }),
      tap((hasAccess: boolean) => {
        if(!hasAccess) this.planInterceptorHandler(planItem);
      }),

    )
  }

  canAccess(planItem: PlanItemTypeEnum){
    return this.storeService.baseData$.pipe(
      map((bootstrap: null | BootstrapperModel) => {
        if(!bootstrap) return false;

        if(!bootstrap.userPlanData) return false;

        return bootstrap.userPlanData.itemAccess.find(f => f.type == planItem)?.hasAccess || false;
      }),
      tap((hasAccess: boolean) => {
        if(!hasAccess) this.planInterceptorHandler(planItem);
      }),
    )
  }


  planInterceptorHandler(planEnum: PlanItemTypeEnum) {
    const userPlanData = this.storeService.getBaseData()?.userPlanData;
    const modalRef = this.ngbModal.open(PlanUpgradeModalComponent, this.configService.modalOptions());
    modalRef.componentInstance.planLevel = userPlanData?.name;
    modalRef.componentInstance.expiredDate = userPlanData?.expireAt;
    modalRef.componentInstance.restrictionReason = planEnum;
  }
}

