import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {ResponseModel} from "@shared/models/response.model";
import {ApiService} from "@shared/services/api.service";
import {PlanDowngrade, PlanPackageInterface} from "@app/modules/plans/models/plans.interface";
import {IUserPlanData} from "@shared/models/i-user-plan-data";
import {PlanItemTypeEnum} from "@app/modules/plans/enums/plan-item-type.enum";

@Injectable({
  providedIn: 'root'
})
export class PlansService {

  constructor(private apiService: ApiService) {
  }

  getPlans(): Observable<ResponseModel<PlanPackageInterface[]>> {
    const url = `user/me/plan/package`;
    return this.apiService.get(url);
  }

  buyFreePlan(): Observable<ResponseModel<IUserPlanData>> {
    const url = 'user/me/plan/package/free/assign'
    return this.apiService.post(url, null);
  }

  getLimitation(planItemEnum: PlanItemTypeEnum): Observable<ResponseModel<boolean>> {
    const url = `plan/${planItemEnum}/limitation`
    return this.apiService.get(url);
  }

  downgrade(planPackageId: number): Observable<ResponseModel<PlanDowngrade>> {
    const url = `user/me/plan/package/${planPackageId}/downgrade/check`;
    return this.apiService.get(url);
  }
}
