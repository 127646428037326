import {Injectable} from '@angular/core';
import {UntilDestroy} from "@ngneat/until-destroy";
import {StoreService} from "@shared/services/store.service";
import {map, switchMap, take} from "rxjs/operators";
import {Observable, of} from "rxjs";
import {ResponseModel} from "@shared/models/response.model";
import {ApiService} from "@shared/services/api.service";
import {TokenService} from "@shared/services/token.service";
import {PrefixModel} from "@shared/models/prefix.model";

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class PrefixNumberService {

  constructor(
    private storeService: StoreService,
    private apiService: ApiService,
  ) {
  }


  getPrefixData(): Observable<ResponseModel<PrefixModel>> {
    const url = 'base-data/prefix';
    return this.apiService.get(url);
  }


  storePrefixData(): any {
    return this.fetchOrGetDataPrefix().pipe(
      map((response: PrefixModel): any => {
        return this.saveData(response);
      }),
    )

  }

  fetchOrGetDataPrefix(): Observable<PrefixModel> {
    return this.storeService.prefix$.pipe(
      take(1),
      switchMap((localValue) => {
          if (!localValue) {
            return this.getPrefixData()
              .pipe(
                map((response: ResponseModel<PrefixModel>) => {
                  return response.result
                })
              );


          } else {
            return of(localValue);
          }
        }
      ),
      map((data: PrefixModel): PrefixModel => {
          return data
        }
      )
    )
  }

  saveData(data: PrefixModel) {
    if (data) {
      this.storeService.setPrefix(data);
      return true;
    } else {
      return false;
    }
  }




}
