export enum SendStatusEnum {
    InternalServerError = 0,
    Success = 1,
    ApiKeyIsWrong = 10,
    ApiKeyIsInactive = 11,
    ApiKeyIsLimitedToIp = 12,
    UserAccountIsDeactivated = 13,
    UserAccountIsSuspended = 14,
    PlanItemAccessDenied = 15,
    InvalidRequestModel = 16,
    RequestRateLimitExceeded = 20,
    LineNumberIsWrong = 101,
    CreditNotEnough = 102,
    NullOrEmptyMessageText = 103,
    MobileIsWrong = 104,
    TooManyMobileListCount = 105,
    TooManyMessageTextListCount = 106,
    EmptyMobileList = 107,
    EmptyMessageTextsList = 108,
    InvalidSendDateTime = 109,
    MobilesAndMessageTextsNotCompatible = 110,
    IdIsWrong = 111,
    ScheduledMessagesNotFound = 112,
    TemplateNotFound = 113,
    LengthOfParameterValueIsLongerThanAllowed = 114,
    AllMobilesAreInBlackList = 115,
    ParameterNameIsNullOrEmpty = 116,
    InvalidFrequentMessageText = 117,
    MessageCountOutOfRange = 118,
    CustomFastSendTemplatePlanLimitation = 119
}

