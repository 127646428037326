import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {TextBuilderTypeEnum} from "../../../../text-builder/models/text-builder-type.enum";
import {SendMessageService} from "../../../../send-message/services/send-message.service";
import {SharedService} from "@shared/services/shared.service";

@Component({
  selector: 'app-modify-text-message-modal',
  templateUrl: './modify-text-message-modal.component.html',
  styleUrls: ['./modify-text-message-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModifyTextMessageModalComponent implements OnInit {
  @Input() defaultText!: string;
  textBuilderTypeEnum = TextBuilderTypeEnum;
  text: string = '';
  maxLengthValid = false;

  constructor(private sendMessageService: SendMessageService,
              private router: Router,
              private ngbActiveModal: NgbActiveModal,
              public activeModal: NgbActiveModal,
              public shareService: SharedService) {
  }

  ngOnInit(): void {
    this.text = this.defaultText;
  }

  validateMaxLength($event: boolean) {
    this.maxLengthValid = $event;
  }

  messageTextChanged($event: string) {
    this.text = $event;
  }

  onSubmit() {
    this.ngbActiveModal.close(this.text);
  }
}
