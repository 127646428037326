import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-bootstrapper-notify-modal',
  templateUrl: './bootstrapper-notify-modal.component.html',
  styleUrls: ['./bootstrapper-notify-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BootstrapperNotifyModalComponent implements OnInit {
  @Input() modalHeader!: string;

  @Input() messages!: { title: string, id: number , showAtEntry: boolean , content: string}[];
  constructor(private ngbActiveModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  onSubmit() {
    this.ngbActiveModal.close(this.messages.map(a=> a.id));
  }

}
