import {Injectable} from '@angular/core';
import {ApiService} from "@shared/services/api.service";
import {Observable} from "rxjs";
import {ResponseModel} from "@shared/models/response.model";
import {UserInfoModel} from "@app/modules/user-account/models/user-info.model";

@Injectable({
  providedIn: 'root'
})
export class PasswordModalService {

  constructor(private apiService: ApiService) {
  }

  setPassword(password: string, isGuest: boolean): Observable<ResponseModel<boolean>> {
    const url = `${isGuest ? 'guest' : 'user'}/password`;
    return this.apiService.post(url, password);
  }
}
