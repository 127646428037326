import {Component, OnInit} from '@angular/core';
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {delay, filter, map} from "rxjs/operators";
import {NavigationEnd, NavigationStart, Router} from "@angular/router";

@UntilDestroy()
@Component({
    selector: 'app-initial-loading',
    templateUrl: './initial-loading.component.html',
    styleUrls: ['./initial-loading.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush
})
export class InitialLoadingComponent implements OnInit {
    image1: string = '../../../assets/images/icons/loading/logo.png';
    image2: string = '../../../assets/images/icons/loading/logo-border.png';

    isFirstTime: boolean = true;
    show: boolean = false;
    paths = [
        '/complete-information',
        '/dashboard',
        '/contacts',
        '/notification',
        '/send-message',
        '/report',
        '/features',
        '/fast-send',
        '/ticket',
        '/template',
        '/numbers',
        '/add-fund',
        '/financial',
        '/developer',
        '/account',
        '/sales-cooperation',
        '/plans',
    ];

    constructor(
        private router: Router,
    ) {
    }

    ngOnInit(): void {
        this.router.events.pipe(
            untilDestroyed(this),
            filter((event) => event instanceof NavigationStart),
            map((event: any): any => {
                if (this.isFirstTime) {
                    if (event.url === '/') {
                        this.show = true;
                    } else {
                        this.show = this.containsRoutes(event.url, this.paths);
                    }
                }
            }),
        ).subscribe()


        this.router.events.pipe(
            untilDestroyed(this),
            filter((event) => event instanceof NavigationEnd),
            delay(1500),
            map((event: any): any => {
                if (this.show) this.isFirstTime = false;
                this.show = false;
            }),
        ).subscribe()

    }

    containsRoutes(urlString: string, Routes: string[]) {
        for (const route of Routes) {
            if (urlString.includes(route)) {
                return true;
            }
        }
        return false;
    }
}

