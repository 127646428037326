import {Injectable} from "@angular/core";
import {TokenService} from "@shared/services/token.service";
import jwtDecode from "jwt-decode";

@Injectable({
  providedIn: 'root'
})

export class DemoHandler {
  constructor(private tokenService: TokenService) {
  }

  hasDemo(): boolean {
    if (this.tokenService.getAccessToken()) {
      const tokenDetails: any = jwtDecode(this.tokenService.getAccessToken());
      return (tokenDetails.IsDemo);
    }
    return false;
  }
}
