<div class="modal-header ">
  <h1>{{modalHeader}}</h1>
</div>

<div class="modal-body">
  <p>کاربر گرامی به سامانه پیام کوتاه SMS.ir خوش آمدید</p>
  <p>ضمن تشکر از انتخاب شما ، لطفا قبل از استفاده از سامانه کلیه مواردی که شرح داده می&zwnj;شود را به دقت مطالعه
    نمایید.</p>
  <p> توجه داشته باشید تایید و پذیرش این موارد به منزله این خواهد بود که شما تابع قوانین سامانه پیامک می&zwnj;باشید
    و تمامی
    شرایط و قوانین استفاده از خدمات سایت را قبول نموده&zwnj;اید.</p>

  <ul>
    <li>کلیه فعالیت&zwnj;های سایت مطابق با قوانین وموازین کشور می&zwnj;باشد و هرگونه تخطی از آن عواقب بعدی را برای
      مخاطب در برخواهد داشت.
    </li>


    <li>کلیه پرداخت‌هایی که در این سامانه صورت می‌پذیرد (به استثنا شماره اختصاصی و پلن) ، و در صورت موجود بودن و استفاده نشدن ،
      <strong>تا 7 روز از زمان پرداخت قابل بازگشت می‌باشد.</strong>

      اما پس از آن به هیچ عنوان امکان پذیر نمی‌باشد.
      در صورت استفاده از پیامک شارژ یا بسته پیامک امکان عودت وجه وجود ندارد(امکان برگشت مبلغ به میزان کل بسته خریداری شده وجود دارد نه بخشی از آن)
    </li>


    <li>در صورت تغییر قیمت از سوی نهادهای مرجع و اپراتورها، هزینه&zwnj;ی ارسال پیامک&zwnj;های خریداری شده با قیمت جدید
      محاسبه خواهد شد.
    </li>
    <li>هرگونه ارسال پیامک با مضامین زیر ممنوع بوده و در صورتی که این موارد مشاهده شود علاوه بر مسدود نمودن پنل ، در
      صورت درخواست سازمان&zwnj;ها و یا ارگان&zwnj;های مرتبط تمامی اطلاعات فرد خاطی ارائه خواهد شد.
    </li>
    <li>ارسال هرگونه پیامک غیرمتعارف ، نظیر شکایت ، جوک&zwnj;های نامتعارف ، برنده شدن جایزه به هر نوعی ، و دریافت
      اطلاعات
      بانکی یا مشخصات اشخاص ، ارسال پیامک سیاسی (با مجوز یا بدون مجوز) ، ارسال سایت&zwnj;های شرط
      بندی ، ارسال پیامک حاوی شماره کارت و شماره حساب ( بدون مجوز) ، هرگونه پیامک غیراخلاقی و هرگونه پیامکی که
      باعث تشویش اذهان عمومی شود.
    </li>
    <li>در زمان ثبت نام شما موظف می&zwnj;باشید که اطلاعات دقیق وارد نمایید و پس از آن مدارک لازم را به صورت واقعی آپلود
      نمایید. در صورت استفاده از مشخصات شخص دیگری در سامانه، تبعات قانونی آن متوجه شما خواهد بود.
    </li>
    <li>تمامی اطلاعات خود را به صورت صحیح وارد نمایید. درصورتی که اطلاعات اشتباهی توسط شما اعلام شود ، علاوه بر
      مسدود نمودن پنل ، شرکت این حق را برای خود محفوظ می&zwnj;دارد که در صورت نیاز از مراجع قانونی پیگیری&zwnj;های
      لازم را انجام دهد.
    </li>
    <li>در صورتی که بر اثر استفاده ناصحیح از سامانه پیامک ضرر و زیانی متوجه شخص شود شرکت هیچ گونه تعهدی نسبت به آن
      نخواهد
      داشت و بازگشت هزینه صورت نمی&zwnj;گیرد.
    </li>
    <li>هرگونه نفوذ دراطلاعات سایت و یا سعی و تلاش برای ایجاد اختلال در عملکرد سامانه پیامک غیرقانونی بوده و
      با متخلفان برخورد خواهد شد.
    </li>
  </ul>

  <p>در صورت عدم تمدید سامانه، اطلاعات و شارژ ، بر اساس زمانبندی زیر نگهداری خواهد شد:</p>
  <ul>
    <li>کلیه اطلاعات کاربران تا 3 ماه پس از انقضای سامانه، حفظ و نگهداری خواهد شد و در هر لحظه قابل بازگشت
      می&zwnj;باشد.
    </li>
    <li>در مدت زمان بین 3 الی 6 ماه پس از انقضای سامانه، اعتبار موجود کسر خواهد شد و دیگر امکان استفاده نخواهد داشت.
    </li>
    <li>پس از گذشت 6 ماه از تاریخ انقضای سامانه، سامانه به صورت کلی حذف خواهد شد. بدیهی است کلیه اطلاعات موجود اعم از
      مخاطبان و گزارش‌های ارسال و دریافت نیز به همراه سامانه حذف خواهد شد و به هیچ عنوان قابل استفاده نخواهد بود.
    </li>
    <li>همچنین پس از سپری شدن این زمان (بیش از 6 ماه) خطوط ثبت شده نیز سلب امتیاز می&zwnj;گردد.</li>
  </ul>

  <p>شرکت تمام تلاش خود را برای جلوگیری از نفوذ هکرها و همچنین حفظ و نگهداری اطلاعات شما به صورت خصوصی
    انجام می دهد. اطلاعات شما به هیچ عنوان توسط شرکت مورد استفاده قرار نخواهد گرفت.</p>
  <p></p>
  <p>در زمان&zwnj;هایی که اپراتورهای ارائه دهنده سرویس دچار مشکل می&zwnj;شوند و امکان ارسال و دریافت پیامک
    مقدور نمی&zwnj;باشد، شرکت اطلاع رسانی&zwnj;های لازم را انجام خواهد داد. اما از آنجا که این اختلالات
    بدون اطلاع قبلی انجام می‌شود ممکن است در برخی موارد این قطعی ها با تاخیر اطلاع رسانی شود.</p>

  <p>پیشاپیش از اینکه با صبر و شکیبایی خود در برخی زمانها (هرچند کوتاه) که ممکن است به دلایلی (ضعف در بسترهای
    مخابراتی، قطعی اپراتور، به‌روزرسانی نرم افزار، ارتقا سرور و ...) امکان ارائه خدمات به شما را نداشته باشیم، ما را
    یاری
    می&zwnj;نمایید، متشکریم.</p>

  <br>
  <p>از اعتماد شما سپاسگزاریم</p>
  <br>
</div>

<div class="modal-footer text-center">
  <button (click)="activeModal.dismiss()" class="btn success-btn">
    متوجه شدم!
  </button>
</div>
