export enum UserCompanyTypeEnum {
  PrivateStock = 1,
  PublicStock = 2,
  LimitedLiability = 3,
  GeneralPartnership = 4,
  StateOwned = 5,
  Ministry = 6,
  Embassy = 7,
  Mosque = 8,
  School = 9,
  NGO = 10,
  Other = 11,
  Institute = 12
}
