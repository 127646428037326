import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject} from "rxjs";
import {UserInfoModel} from "@app/modules/user-account/models/user-info.model";
import {DeviceType} from "../models/device-type";
import {SidebarModeType} from "../models/sidebar-mode";
import {HelpModeType} from "../../extra-components/help/models/help-mode.type";
import {BootstrapperModel} from "../models/bootstrapper.model";
import {map} from "rxjs/operators";
import {PrefixModel} from "../models/prefix.model";
import {ChangeEmailModel} from "@app/modules/user-account/models/change-email-model";
import jwtDecode from "jwt-decode";
import {IUserPlanData} from "@shared/models/i-user-plan-data";
import {DecodeJWTModel} from "@shared/models/auth-info.model";
import {UserStateEnum} from "@shared/enums/user-state.enum";

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  constructor() {
  }

  private prefix: BehaviorSubject<PrefixModel | null> = new BehaviorSubject<PrefixModel | null>(null);
  prefix$: Observable<PrefixModel | null> = this.prefix.asObservable();

  private token: ReplaySubject<string> = new ReplaySubject<string>(1)
  token$: Observable<string> = this.token.asObservable();

  private isGuest: ReplaySubject<boolean> = new ReplaySubject<boolean>(1)
  isGuest$: Observable<boolean> = this.isGuest.asObservable();


  private userState: BehaviorSubject<UserStateEnum | null> = new BehaviorSubject<UserStateEnum | null>(null)
  userState$: Observable<UserStateEnum | null> = this.userState.asObservable();


  private userInfo: BehaviorSubject<UserInfoModel | null> = new BehaviorSubject<UserInfoModel | null>(null)
  userInfo$: Observable<UserInfoModel | null> = this.userInfo.asObservable();

  private deviceMode: ReplaySubject<DeviceType> = new ReplaySubject<DeviceType>(1)
  deviceMode$: Observable<DeviceType> = this.deviceMode.asObservable();

  private sidebarMode: ReplaySubject<SidebarModeType> = new ReplaySubject<SidebarModeType>(1)
  sidebarMode$: Observable<SidebarModeType> = this.sidebarMode.asObservable();

  private helpMode: BehaviorSubject<HelpModeType> = new BehaviorSubject<HelpModeType>('closed')
  helpMode$: Observable<HelpModeType> = this.helpMode.asObservable();

  private baseData: BehaviorSubject<BootstrapperModel | null> = new BehaviorSubject<BootstrapperModel | null>(null)
  baseData$: Observable<BootstrapperModel | null> = this.baseData.asObservable();


  setBaseData(payload: BootstrapperModel) {
    this.baseData.next(payload)
  }

  getBaseData(): BootstrapperModel | null {
    return this.baseData.getValue();
  }

  setHelpMode(payload: HelpModeType) {
    this.helpMode.next(payload)
  }

  setDeviceMode(payload: DeviceType) {
    this.deviceMode.next(payload)
  }

  setIsGuest(payload: boolean | undefined) {
    this.isGuest.next(payload)
  }

  setToken(payload: string | undefined) {
    this.token.next(payload)
  }

  setPrefix(payload: PrefixModel) {
    this.prefix.next(payload)
  }

  setUserState(payload: UserStateEnum | null) {
    this.userState.next(payload);
  }

  getUserState(){
    return this.userState.getValue()
  }

  retrieveDataFromCookie(payload: string): void {
    const decodeToken: DecodeJWTModel = jwtDecode(payload);
    this.token.next(payload)
    this.isGuest.next(decodeToken.hasOwnProperty('GuestId'))
    decodeToken.hasOwnProperty('State') ? this.setUserState(Number(decodeToken.State) ?? null) : undefined;
  }

  initSidebarMode(deviceMode: DeviceType) {
    switch (deviceMode) {
      case 'mobile':
        this.sidebarMode.next('hidden');
        break;
      case 'tablet':
        this.sidebarMode.next('hidden');
        break;
      case 'desktop':
        this.sidebarMode.next('default');
        break;
      default:
        this.sidebarMode.next('default');
    }
  }

  modifySidebar(mode: SidebarModeType) {
    this.sidebarMode.next(mode);
  }

  getPrefixes(operators?: string[]): Observable<string[]> {
    return this.prefix$.pipe(
      map(res => {
        if(!res) return [];
        if (operators) {
          const prefixesDic: { [key: string]: any } = res;
          return [].concat(...operators.map(key => prefixesDic[key]));
        } else {
          return [].concat(...Object.values(res));
        }
      })
    )
  }

  confirmAlert(alertId: number) {
    let data = this.baseData.getValue();
    if (data) {
      data = {
        ...data,
        userData: {
          ...data.userData,
          confirmation: (data.userData.confirmation - Number(alertId))
        }
      }
    }
    this.baseData.next(data)
  }

  getUserInfoSuccess(payload: UserInfoModel) {
    let userInfo = this.userInfo.getValue();
    userInfo = {
      ...userInfo,
      ...payload
    }
    this.userInfo.next(userInfo);
  }

  avatarUpdatedSuccess(newUrl: string | null) {
    let baseData = this.baseData.getValue();
    let userData = this.userInfo.getValue();
    if (baseData) {
      baseData = {
        ...baseData,
        userData: {
          ...baseData.userData,
          profileImageURL: newUrl
        }
      }
    }
    if (userData) {
      userData = {
        ...userData,
        profileImageURL: newUrl
      }
    }
    this.baseData.next(baseData);
    this.userInfo.next(userData);
  }

  emailUpdatedSuccess(payload: ChangeEmailModel) {
    let userData = this.userInfo.getValue();
    if (userData) {
      userData = {
        ...userData,
        email: payload.email
      }
    }
    this.userInfo.next(userData);
  }

  setPlanData(plan: IUserPlanData) {
    let data = this.baseData.getValue();
    if (data) {
      data = {
        ...data,
        userPlanData: {...plan},
      }
    }
    this.baseData.next(data)
  }
  getPlanExpireAt() {
    let data = this.baseData.getValue();
    return data?.userPlanData?.expireAt;
  }

  getPlanCreatedAt() {
    let data = this.baseData.getValue();
    return data?.userPlanData?.createdAt;
  }
}
