import {Injectable} from '@angular/core';
import {UntilDestroy} from "@ngneat/until-destroy";
import {StoreService} from "@shared/services/store.service";
import {map, mergeMap, switchMap, take} from "rxjs/operators";
import {Observable, of} from "rxjs";
import {ResponseModel} from "@shared/models/response.model";
import {BootstrapperModel} from "@shared/models/bootstrapper.model";
import {ApiService} from "@shared/services/api.service";
import {PasswordModalComponent} from "@shared/components/password-modal/password-modal.component";
import {GuestApiService} from "@app/modules/guest-info/services/guest-api.service";
import {AuthApiService} from "../../auth/services/auth-api.service";
import {Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {GuestService} from "@app/modules/guest-info/services/guest.service";
import {ConfigService} from "@shared/services/config.service";
import {ModalService} from "@shared/services/modal.service";
import {HelpService} from "../../extra-components/help/services/help.service";
import {NotificationApiService} from "@app/modules/notification/services/notification-api.service";
import {TokenService} from "@shared/services/token.service";
import {GuestInfoModel} from "@app/modules/guest-info/models/guest-info.model";


@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class BootstrapAppService {

  constructor(
    private storeService: StoreService,
    private ngbModal: NgbModal,
    private guestApiService: GuestApiService,
    private guestService: GuestService,
    private configService: ConfigService,
    private modalService: ModalService,
    private helpService: HelpService,
    private notificationService: NotificationApiService,
    private apiService: ApiService,
    private tokenService: TokenService,
  ) {
  }


  getBootstrapperData(): Observable<ResponseModel<BootstrapperModel>> {
    const url = 'bootstrapper';
    return this.apiService.get(url, true);
  }

  resetPasswordModal(): any {
    return this.ngbModal.open(PasswordModalComponent, this.configService.modalOptions('sm', 'static')).result
  }





  storeBootstrapperData(): Observable<boolean> {
    return this.storeService.isGuest$
      .pipe(
        take(1),
        mergeMap((isGuest: boolean): Observable<{
          response: GuestInfoModel | BootstrapperModel;
          isGuest: boolean
        }> => {
          return this.handleData(isGuest)

        }),
        map(({response, isGuest}: {
          response: GuestInfoModel | BootstrapperModel;
          isGuest: boolean
        }): any => {

          return this.saveData({response, isGuest});

        }),
      )
  }


  handleData(isGuest: boolean): Observable<{
    response: GuestInfoModel | BootstrapperModel;
    isGuest: boolean
  }> {
    if (isGuest) {
      return this.fetchOrGetDataGuest(isGuest);
    } else {
      return this.fetchOrGetDataBootstrap(isGuest);
    }
  }

  saveData(data: {
    response: GuestInfoModel | BootstrapperModel;
    isGuest: boolean
  }) {

    if (data.response && this.tokenService.getAccessToken()) {
      if (data.isGuest) {
        this.guestService.setInfo(data.response as GuestInfoModel);
      } else {
        this.helpService.helpItems = (data.response as BootstrapperModel)['helpItems'];
        this.storeService.setBaseData(data.response as BootstrapperModel);
      }
      return true;
    } else {
      return false;
    }

  }


  bootstrapperNotify(response: ResponseModel<BootstrapperModel>) {
    let unreadCustomNotifications = response.result.notificationsData.unreadCustomNotifications
    if (unreadCustomNotifications.length > 0 && unreadCustomNotifications.find(a => a.showAtEntry)) {
      if (!this.ngbModal.hasOpenModals()) {
        this.modalService.BootstrapperNotify(unreadCustomNotifications.filter(a => a.showAtEntry)).then(
          (ids) => {
            this.notificationService.patchSystemNotificationsUnread(ids).subscribe(
              () => {
                const baseData = this.storeService.getBaseData();
                if (baseData) {
                  this.storeService.setBaseData({
                    ...baseData,
                    notificationsData: {
                      ...baseData.notificationsData,
                      totalUnreadCustomNotificationsCount: baseData.notificationsData.totalUnreadCustomNotificationsCount - ids.length,
                    }
                  });
                }
              }
            )
          }
        )
      }
    }
  }

  fetchOrGetDataBootstrap(isGuest: boolean): Observable<{ response: BootstrapperModel, isGuest: boolean }> {
    return this.storeService.baseData$.pipe(
      take(1),
      switchMap((localValue) => {
          if (!localValue) {
            return this.getBootstrapperData()
              .pipe(
                map((response: ResponseModel<BootstrapperModel>) => {
                  if (response.result.userData.hasPassword) {
                    this.bootstrapperNotify(response)
                  } else {
                    this.resetPasswordModal().then(
                      (isPasswordSet: boolean) => {
                        if (isPasswordSet) {
                          this.bootstrapperNotify(response)
                        }
                      }
                    );
                  }
                  return response.result
                })
              );


          } else {
            return of(localValue);
          }
        }
      ),
      map((data: BootstrapperModel | null): { response: BootstrapperModel, isGuest: boolean } => {
          return {response: data as BootstrapperModel, isGuest}
        }
      )
    )
  }


  fetchOrGetDataGuest(isGuest: boolean): Observable<{ response: GuestInfoModel, isGuest: boolean }> {
    return this.guestService.info$.pipe(
      take(1),
      switchMap((localValue) => {
          if (!localValue) {
            return this.guestApiService.getGuestInfo()
              .pipe(
                map((response: ResponseModel<GuestInfoModel>) => {
                  if (!response.result.hasPassword) {
                    this.resetPasswordModal();
                  }
                  return response.result
                })
              );


          } else {
            return of(localValue);
          }
        }
      ),
      map((data: GuestInfoModel | null): { response: GuestInfoModel, isGuest: boolean } => {
          return {response: data as GuestInfoModel, isGuest}
        }
      )
    )
  }
}
