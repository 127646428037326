<div
  class="loading-container"
  [ngClass]="{ transparent: loadingOptions.transparent ,absolute: loadingOptions.position}"
  *ngIf="loadingOptions"
>
  <div
    class="c-loader-spinner t-loader-spinner"
    [ngClass]="loadingOptions.size"
    [ngStyle]="{
      'border-color': loadingOptions.borderColor
        ? loadingOptions.borderColor
        : 'rgba(255, 255, 255, 0.2)',
      'border-top-color': loadingOptions.color ? loadingOptions.color : '#fff'
    }"
  ></div>
</div>
