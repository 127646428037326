import {Injectable} from '@angular/core'
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {StoreService} from "../services/store.service";
import {TokenService} from "@shared/services/token.service";
import {BootstrapAppService} from "@shared/services/bootstrap-app.service";
import {Observable, of} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SystemGuard implements CanActivate {

  constructor(
    private storeService: StoreService,
    private router: Router,
    private tokenService: TokenService,
    private bootstrapAppService: BootstrapAppService
  ) {
  }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    if (this.tokenService.getAccessToken()) {
      return this.bootstrapAppService.storeBootstrapperData()
    } else {
        window.location.href = 'https://sms.ir/';
      return of(false);
    }
  }


}
