<button
  *ngIf="{loading : loadingService.buttonLoading$ | async} as O"
  [disabled]="onlyDisabled || O.loading || disabled"
  [ngClass]="{'not-allowed': onlyDisabled || O.loading || disabled}"
  [attr.class]="setClass"
  (click)="onButtonClick()"
  >
  <ng-content></ng-content>
  <div *ngIf="O.loading && isClicked" class="mr-2">
    <app-loading  [loadingOptions]="loadingConfig"></app-loading>
  </div>

</button>
