import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpEventType, HttpResponse, HttpHeaders, HttpClient
} from '@angular/common/http';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {catchError, filter, finalize, map, switchMap, take} from 'rxjs/operators';

import {LoadingService} from './loading.service';
import {HandleErrorService} from './handle-error.service';
import {ToastifyService} from './toastify.service';
import {TokenService} from './token.service';
import {ConfigService} from '@shared/services/config.service';
import {DemoHandler} from '@shared/services/demo-handler.service';

@Injectable()
export class HttpClientInterceptor implements HttpInterceptor {

  constructor(private loadingService: LoadingService,
              private toastifyService: ToastifyService,
              private tokenService: TokenService,
              private httpClient: HttpClient,
              private configService: ConfigService,
              private demoHandler: DemoHandler,
              private router: Router,
              private handleErrorService: HandleErrorService,) {
  }

  private isRefreshing = false;
  refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  JWTDemoHandler(): boolean {
    const isDemo = this.demoHandler.hasDemo();
    if (isDemo) {
      this.toastifyService.error('کاربر گرامی، با عرض پوزش، استفاده از این قابلیت در حالت دمو امکان پذیر نمی‌باشد.');
    }
    return isDemo;
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any> | any> {
    if (req.method !== 'GET' && this.JWTDemoHandler()) {
      return of();
    }

    let request = this.tokenService.getAccessToken() ? req.clone({
      headers: req.headers.set('Authorization', 'Bearer ' + this.tokenService.getAccessToken())
    }) : req;

    return next.handle(request)
      .pipe(
        map((event: HttpEvent<any>) => {
          if (event.type === HttpEventType.Sent) {
            const requestMethod: string = req.method;
            this.loadingService.setButtonLoading(true);
          }
          if (event instanceof HttpResponse) {
            this.loadingService.setButtonLoading(false);
            if (typeof event.body === 'object' && event.status === 200 && !event.body.success) {
              this.handleErrorService.handle(event.body.errorCode);
              throw new Error(event.body.errorCode);
            }
          }
          return event;
        }),
        catchError((err) => {
          this.loadingService.setButtonLoading(false);
          if (err.status && (err.status === 403)) {
            this.tokenService.setIsAuthenticated(false);
            this.tokenService.logOut();
          }
          if (err.status && (err.status === 401)) {
            if (err.url.slice(-5) === 'token' || !this.tokenService.getAccessToken() || !this.tokenService.getRefreshToken()) {
              this.isRefreshing = false;

              this.tokenService.logOut();
              return throwError(err);
            }
            return this.checkRefreshToken(request, next);
          }
          if (err.status && (err.status !== 400 && err.status !== 404)) {
            this.handleErrorService.handle(err);
          }

          if (err.status && err.status === 404) {
            this.router.navigate(['/404']).then();
          }
          return throwError(err);
        }),
        finalize(() => {
          this.loadingService.setButtonLoading(false);
        }),
      );
  }

  checkRefreshToken(req: any, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const refreshToken = this.tokenService.getRefreshToken();
      if (refreshToken) {
        const configOptions: any = {
          observe: 'response',
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
          }),
        };
        return this.httpClient.put(`${this.configService.baseURL}auth/token`, JSON.stringify(refreshToken), configOptions)
          .pipe(
            switchMap((res: any) => {
              this.isRefreshing = false;
              let isStateChanged = this.tokenService.setTokenItemsWithRefreshToken((res as any).body.result, refreshToken.trim());
              this.refreshTokenSubject.next((res as any).body.result);
              if (isStateChanged){
                return of(null)
              }
              return next.handle(this.addTokenHeader(req, (res as any).body.result));
            })
          );
      }
    }
    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(req, token)))
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({headers: request.headers.set('Authorization', 'Bearer ' + token)});
  }

}
