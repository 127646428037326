import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {SharedService} from "../../services/shared.service";

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteModalComponent implements OnInit {

  @Input() svg!: string;
  @Input() modalHeader!: string;
  @Input() modalText!: string;
  @Input() modalConfirmButton!: string;
  @Input() modalCancelButton!: string;

  constructor(public sharedService: SharedService,
              public ngbActiveModal: NgbActiveModal) {
  }

  ngOnInit(): void {
  }

  onSubmit() {
    this.ngbActiveModal.close(true);
  }
}
