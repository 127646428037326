export enum UserCompanyActivityFieldEnum {
  Bank = 1,
  Service = 2,
  Manufacturing = 3,
  Advertising = 4,
  Insurance = 5,
  Media = 6,
  IT = 7,
  Other = 8,
  SportsCultural = 9
}
