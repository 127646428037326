import {Injectable} from '@angular/core';
import {Observable} from "rxjs";

import {ResponseModel} from "@shared/models/response.model";
import {ApiService} from "@shared/services/api.service";


@Injectable({
  providedIn: 'root'
})
export class CriticsSuggestionsService {

  constructor(private apiService: ApiService) {
  }

  addFeedback(payload: { content: string }): Observable<ResponseModel<any>> {
    const url = `user/feedback`;
    return this.apiService.post(url, payload);
  }
}
