<div class="update-wrapper">

  <div class="modal-header text-center ">
    <img src="assets/images/icons/modal/setting.svg" alt="setting-icon">
    <h1>به‌روزرسانی</h1>
  </div>

  <div class="modal-body text-center mt-3">
    <p class="mt-3">
      {{modalText}}
    </p>
    <p class="mt-3">
      در صورت به روزرسانی، فرآیند فعلی شما متوقف شده و اپلیکیشن مجددا بارگذاری می شود.
    </p>
  </div>
  <div class="modal-footer text-center">
    <button (click)="onSubmit()"
            class="btn success-btn">به روزرسانی</button>
    <button (click)="ngbActiveModal.dismiss()"
            class="btn dark-btn mr-3">بازگشت</button>
  </div>

</div>
