<div class="modal-header text-center show-modal-data">
  <img *ngIf="svg" [src]="svg ? svg : sharedService.EditSVG" alt="edit-icon">
  <h5>{{modalHeader}}</h5>
</div>

<div class="modal-body text-center">
  <div class="text-center modal-table">
    <div class="modal-table__item" *ngFor="let itemKey of objectKeys(data)">
      <div class="modal-table__item--header">{{itemKey}}</div>
      <div class="modal-table__item--value" id="scrollbar-style">{{data[itemKey]}}</div>
    </div>
  </div>
</div>
<div class="modal-footer text-center">
    <button class="btn dark-btn" (click)="activeModal.dismiss()">
      بازگشت
    </button>
</div>

