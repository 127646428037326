import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {UtilityService} from "../services/utility.service";
import moment, {utc} from "jalali-moment";

@Pipe({
  name: 'wsDate'
})

@Injectable({
  providedIn: 'root'
})
export class WsDatePipe implements PipeTransform {

  constructor(private utilityService: UtilityService) {
  }

  transform(value: any, isUtc: boolean = false): string {
    if(isUtc) {
      return  moment.unix(value).locale('fa').format('YYYY/MM/DD')
    }
    const expression = isNaN(value) ? value : `${value}`;
    const date = this.utilityService.convertStringToDateObject(expression);
    return `${date.year}/${date.month}/${date.day}`;
  }

}
